import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const emptyIconStyles = cva(
	'p-3 bg-gradient-to-tr from-plum7 to-violet7 rounded-full overflow-visible',
	{
		variants: {
			size: {xs: 'w-6 h-6', sm: 'w-8 h-8', md: 'w-12 h-12', lg: 'w-16 h-16'},
			color: {
				neutral: 'text-mauve12',
				primary: 'text-violet12',
				secondary: 'text-plum12',
				success: 'text-green12',
				warning: 'text-yellow12',
				danger: 'text-red12',
				info: 'text-blue12',
			},
		},
		defaultVariants: {size: 'md', color: 'neutral'},
	},
);

export type EmptyIconVariantProps = VariantProps<typeof emptyIconStyles>;
