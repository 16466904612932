import {createElement, forwardRef} from 'react';
import {twMerge} from 'tailwind-merge';

import type {ComponentPropsWithoutRef, ReactNode} from 'react';
import {emptyIconStyles} from './styles';
import type {Icon} from '../../types';
import type {EmptyIconVariantProps} from './styles';

export type EmptyProps = Omit<ComponentPropsWithoutRef<'div'>, 'title'> & {
	icon?: Icon;
	title?: ReactNode;
	subtitle?: ReactNode;
} & EmptyIconVariantProps;

const Empty = forwardRef<HTMLDivElement, EmptyProps>(
	({size, color, icon, title, subtitle, children, className, ...props}, forwardedRef) => (
		<div
			{...props}
			className={twMerge(
				'mx-auto flex h-full w-full max-w-lg flex-col items-center justify-center gap-4 p-4 text-center',
				className,
			)}
			ref={forwardedRef}
		>
			{icon && createElement(icon, {className: emptyIconStyles({size, color})})}
			{(title || subtitle) && (
				<div className="flex flex-col items-center justify-center">
					{title && <h3 className="pb-1 text-lg font-semibold text-mauve12">{title}</h3>}
					{subtitle && <h4 className="text-pretty text-sm text-mauve11">{subtitle}</h4>}
				</div>
			)}
			{children}
		</div>
	),
);

Empty.displayName = 'Empty';

export default Empty;

export * from './styles';
