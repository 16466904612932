import {Empty, TextButton} from '@job-ish/ui/components';
import {IconExclamationCircle} from '@tabler/icons-react';

export const DefaultError = ({error, reset}: {error?: Error; reset?: () => void}) => (
	<Empty
		className="w-full grow"
		icon={IconExclamationCircle}
		size="lg"
		subtitle="Please refresh the page to try again."
		title="Something Went Wrong"
	>
		<div className="flex flex-col items-center">
			<span>If the problem persists, please contact support.</span>
			{error && process.env.NODE_ENV === 'development' && (
				<span className="max-w-xl text-xs text-mauve10">{error.message}</span>
			)}
		</div>
		<TextButton color="primary" onPress={reset}>
			Attempt to Reload
		</TextButton>
	</Empty>
);
