'use client';

import '~/assets/styles/index.css';

import {inter} from '~/assets/fonts/inter';
import {karla} from '~/assets/fonts/karla';
import {DefaultError} from '~/shared/components/error/default';
import {COLOR_MODE_COOKIE} from '~/shared/constants/cookies';
import {Footer} from '@job-ish/blocks/components';
import {useColorMode} from '@job-ish/ui/hooks';
import clsx from 'clsx';

import {Header} from './_/components/header';

const GlobalError = ({error, reset}: {error?: Error; reset?: () => void} = {}) => {
	useColorMode({cookieName: COLOR_MODE_COOKIE});

	return (
		<html className={clsx(inter.variable, karla.variable)} lang="en-us">
			<body>
				<Header />
				<DefaultError error={error} reset={reset} />
				<Footer />
			</body>
		</html>
	);
};

export default GlobalError;
