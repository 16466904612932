import {useCallback, useEffect} from 'react';
import {useCookie, useMedia} from 'react-use';

export type UseColorModeParams = {
	initialValue?: 'dark' | 'light' | 'system';
	cookieName: string;
};

export const useColorMode = ({initialValue = 'system', cookieName}: UseColorModeParams) => {
	const [colorModeCookie, setColorModeCookie] = useCookie(cookieName);
	const colorMode = colorModeCookie || initialValue;
	const prefersDarkMode = useMedia('(prefers-color-scheme: dark)', colorMode === 'dark');

	const setColorMode = useCallback(
		(mode: 'dark' | 'light') => {
			setColorModeCookie(mode, {sameSite: 'lax'});
			if (mode === 'dark') {
				document.body.classList.add('dark-theme');
			} else {
				document.body.classList.remove('dark-theme');
			}
		},
		[setColorModeCookie],
	);

	useEffect(() => {
		if (initialValue === 'system') {
			setColorMode(prefersDarkMode ? 'dark' : 'light');
		} else {
			setColorMode(initialValue);
		}
	}, [initialValue, prefersDarkMode, setColorMode]);

	return colorMode === 'system'
		? prefersDarkMode
			? 'dark'
			: 'light'
		: colorMode === 'dark'
			? 'dark'
			: 'light';
};
